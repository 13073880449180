.list-container {
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap;
}

.list-container .list-items {
  width: 25%;
  padding: 0 15px;
  margin-bottom: 2rem;
}

.list-all{
    margin: 1rem 0;
}