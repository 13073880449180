.video-slider .video-thumb-items {
  position: relative;
  height: 343px;
}

.video-slider .video-thumb-items img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-slider .video-thumb-items .video-views {
  background: #0b0909;
  padding: 5px 8px;
  align-items: center;
  border-radius: 30px;
  display: flex;
  position: absolute;
  top: 16px;
  left: 16px;
  gap: 4px;
  font-size: 0.875rem;
}

.video-slider .video-thumb-items .video-notify {
  background: #0b0909;
  padding: 5px 6px;
  align-items: center;
  border-radius: 50px;
  display: flex;
  position: absolute;
  top: 16px;
  left: 16px;
  gap: 4px;
  font-size: 0.875rem;
}

.video-slider .video-thumb-items .video-notify svg {
  fill: #ffffff;
}

.video-slider .video-thumb-items .video-status {
  background: #db3d12;
  padding: 5px 8px;
  align-items: center;
  border-radius: 30px;
  display: flex;
  position: absolute;
  top: 16px;
  right: 16px;
  gap: 4px;
  color: #ffffff !important;
}

.video-slider .video-thumb-items .video-time {
  background: #43b028;
  color: #ffffff;
  padding: 5px 8px;
  border-radius: 30px;
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 0.875rem;
}

.video-slider .video-thumb-items .video-onAir {
  background: #43b028;
  padding: 5px 8px;
  align-items: center;
  border-radius: 30px;
  display: flex;
  position: absolute;
  top: 16px;
  right: 16px;
  color: #ffffff;
  gap: 4px;
}

.video-main .nextArrow svg,
.video-main .prevArrow svg {
  color: #43b028;
  opacity: 0.5;
}

.video-main .nextArrow {
  position: absolute;
  right: -65px;
  top: 41%;
  z-index: 12;
  background: #ffffff;
  border-radius: 50%;
  cursor: pointer;
  width: 42px;
  height: 42px;
  padding: 9px;
  box-sizing: border-box;
  box-shadow: 0 1px 13px rgb(17 17 17 / 34%);
}

.video-main .prevArrow {
  position: absolute;
  left: -65px;
  top: 41%;
  z-index: 12;
  background: #ffffff;
  border-radius: 50%;
  cursor: pointer;
  width: 42px;
  height: 42px;
  padding: 8px;
  box-sizing: border-box;
  box-shadow: 0 1px 13px rgb(17 17 17 / 34%);
}

.video-main .slick-slide {
  padding: 0 15px;
  box-sizing: border-box;
}

.video-main .slick-slider .slick-list .slick-track {
  margin: 0;
}

.video-slider .video-thumb-items .thumb-detail {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  padding: 0 16px;
  box-sizing: border-box;
}

.video-slider .video-thumb-items .thumb-detail p {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 24px;
  color: #ffffff;
  margin: 8px 0;
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

span.divider {
  height: 1px;
  width: 100%;
  background: #ffffff;
  display: block;
}

.video-slider .video-thumb-items .video-dark-overlay {
  height: 100%;
  width: 100%;
  /* background-image: linear-gradient(transparent, #272727); */
  background: linear-gradient(
    360deg,
    #0b0909 -16.78%,
    rgba(11, 9, 9, 0) 80.39%
  );
  position: absolute;
  top: 0;
  bottom: 0;
}

.video-slider .video-thumb-items .thumb-detail h4 {
  margin: 8px 0;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 16px;
  color: #ffffff;
}

.video-slider .video-thumb-items .thumb-detail h4 span {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 31px;
}

.video-slider .video-thumb-items .thumb-detail img {
  width: 45px;
  height: 45px;
  min-width: 45px;
}

.slick-disabled {
  display: none !important;
}

.recent .video-slider .video-thumb-items .notify-label {
  background: #ffffff;
  color: #000;
  padding: 5px 8px;
  border-radius: 30px;
  position: absolute;
  top: 16px;
  left: 16px;
  font-size: 0.875rem;
}

.recent .video-slider .video-thumb-items .video-time {
  background: #43b028;
  color: #ffffff;
  padding: 5px 8px;
  border-radius: 30px;
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 0.875rem;
}

.recent .video-slider .video-thumb-items .thumb-detail span.follow_count {
  display: block;
  font-size: 1rem;
  font-weight: 300;
  padding-top: 4px;
  color: #ffffff;
}

.recent .video-slider .video-thumb-items .thumb-detail h4 {
  margin-bottom: 12px;
  color: #ffffff;
}

.recent .video-slider .video-thumb-items .thumb-detail h5 {
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 24px;
  color: #ffffff;
}

.recent .video-slider .video-thumb-items .thumb-detail .action-btn {
  padding: 8px 0;
  display: flex;
  gap: 24px;
  align-items: center;
}

.recent .video-slider .video-thumb-items .thumb-detail .action-btn svg {
  display: block;
}

.recent .video-slider .video-thumb-items .thumb-detail .action-btn .bookmark {
  display: flex;
  align-items: center;
  gap: 4px;
}

/* .all .video-slider .video-thumb-items .notify-label {
  background: #ffffff;
  color: #000;
  padding: 5px 8px;
  border-radius: 30px;
  position: absolute;
  top: 16px;
  left: 16px;
  font-size: 0.875rem;
} */

.slick-dots li button:before {
  color: #ffffff;
}

.lightmenuTabs .slick-dots li button:before {
  color: #000000;
}

.slick-dots li.slick-active button:before {
  color: #43b028;
  font-size: 8px;
}

.head_title {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.head_title a {
  text-decoration: none;
}

.head_title a span {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #43b028;
}

.video-streamer-info img {
  width: 30px !important;
  height: 30px !important;
  min-height: 30px !important;
  max-height: 30px !important;
  min-width: 30px !important;
  border-radius: 50% !important;
}

.light_sec svg {
  fill: #000000 !important;
}

.no_data_sec svg {
  max-width: 40px;
  fill: #ffffff;
  display: block;
}

.no_data_sec {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (max-width: 480px) {
  .menuTabs .slick-dots {
    bottom: 0;
    position: relative;
  }
}

@media (min-width: 600px) and (max-width: 1200px) {
  .menuTabs .prevArrow {
    left: -20px;
  }
  .menuTabs .nextArrow {
    right: -20px;
  }
  .video-main .nextArrow {
    right: -5px;
  }
  .video-main .prevArrow {
    left: -5px;
  }
  .home-main .scrollTop span {
    top: auto !important;
    bottom: 25px !important;
    right: 25px !important;
  }
}
