body,
html {
  font-family: "Poppins", sans-serif !important;
  font-size: 1rem;
}

* {
  font-family: "Poppins", sans-serif !important;
  font-size: 1rem;
}

.home-main .scrollTop span {
  background: #ffffff;
  border-radius: 50%;
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 5rem;
  top: 2rem;
  cursor: pointer;
  box-shadow: 0px 6px 18px rgba(7, 6, 6, 0.1);
}

.mobile-menu {
  display: none;
}

.mobile-nav {
  background: #ffffff;
  width: 100%;
  padding: 9px;
  position: relative;
  z-index: 99;
}

.mobile-nav a {
  display: block;
  color: #53b029;
  font-size: 1rem;
  text-decoration: none;
  padding: 10px 12px;
  font-weight: 600;
}

@media only screen and (max-width: 480px) {
  .home-main .scrollTop span {
    right: 2rem;
  }
  .mobile-nav .menuItem {
    color: #53b029;
    margin: 8px;
  }
}

@media only screen and (max-width: 767px) {
  .mobile-nav .menuItem {
    color: #53b029;
    margin: 8px;
  }
  .mobile-menu {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 2rem;
    justify-content: space-between;
  }

  .mobile-menu svg {
    margin: 6px;
    color: #ffffff;
  }

  .side-drawer {
    height: 100%;
    background: #ffffff;
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    max-width: 400px;
    z-index: 200;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
  }

  .side-drawer.open {
    transform: translateX(0);
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .mobile-menu {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 2rem;
    justify-content: space-between;
  }

  .mobile-menu svg {
    margin: 6px;
    color: #ffffff;
  }

  .side-drawer {
    height: 100%;
    background: #ffffff;
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    max-width: 400px;
    z-index: 200;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
  }

  .side-drawer.open {
    transform: translateX(0);
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
  }

  .mobile-nav .menuItem {
    color: #53b029;
    margin: 8px;
  }
}

@media only screen and (min-width: 1025px) {
  .side-drawer {
    display: none;
  }
}

.st1 {
  fill: none;
  stroke: #53b029;
  stroke-width: 32;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.ThemeLight {
  cursor: pointer;
}

.ThemeDark {
  cursor: pointer;
}

.ThemeLight .st0 {
  fill: #53b029;
}

.ThemeDark .st0 {
  fill: #53b029;
}

.menuItem {
  color: #ffffff;
  margin-right: 1rem;
  font-weight: 500;
  /* max-width: 50px;
  min-width: 90px; */
}

.menuItem svg {
  margin-right: 0.5rem;
}

.menuItemLight {
  margin-right: 1rem;
  font-weight: 500;
  /* max-width: 50px;
  min-width: 90px; */
}

.menuItemLight svg {
  margin-right: 0.5rem;
}

.login_user {
  position: relative;
}

.login_user:hover .logout-sec {
  display: block;
}

.logout-sec {
  position: absolute;
  bottom: -30px;
  left: 0;
  display: none;
}

.logout-sec .logout-btn {
  border: 0;
  cursor: pointer;
  font-size: 1rem;
  padding: 3px 20px;
  border-radius: 3px;
}

.header-menu {
  background: #111111;
}

.headerLight-menu .mobile-menu svg {
  color: #53b029 !important;
}

.headerLight-menu {
  background: #ffffff;
}

.headerLight-menu nav div div a {
  color: #111111;
}

.addressMenu {
  color: #ffffff;
}

.addressMenu,
.addressMenuLight {
  display: flex;
  align-items: center;
  gap: 10px;
  /* width: 100%; */
  /* min-width: 126px; */
  /* margin: 0 auto; */
  padding: 0 1rem;
  font-weight: 500;
  white-space: nowrap;
}

.addressMenu svg,
.addressMenuLight svg {
  width: 20px;
}

.addressMenu span,
.addressMenuLight span {
  display: flex;
  align-items: center;
  gap: 10px;
}

.user_address_list {
  padding: 1rem 0.2rem;
}

.user_address_list div label {
  display: flex;
  padding: 0.3rem 0;
  font-size: 1.1rem;
  align-items: flex-start;
}

.user_address_list div label input {
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.address_form {
  padding: 0 1rem 1rem 1rem;
}

.address_form button.submit_btn {
  background: linear-gradient(147.67deg, #43b028 24.24%, #fbce2e 145.04%);
  color: #ffffff;
  font-weight: 500;
  font-size: 1rem;
  border: 0;
  border-radius: 50px;
  padding: 0.4rem 1.5rem;
  cursor: pointer;
}

.user_address_list div label span {
  display: block;
  width: 100%;
  font-weight: 500;
}

.user_address_list div label input[type="radio"] {
  /* remove standard background appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* create custom radiobutton appearance */
  display: inline-block;
  width: 25px;
  height: 25px;
  min-width: 25px;
  min-height: 25px;
  padding: 6px;
  /* background-color only for content */
  background-clip: content-box;
  border: 2px solid #bbbbbb;
  background-color: #e7e6e7;
  border-radius: 50%;
}

.user_address_list div label input[type="radio"]:checked {
  background-color: #53b029;
}

.address_action_btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.address_action_btn .btn_link {
  color: #53b029;
  border: 0;
  background: transparent;
  font-size: 1rem;
  text-align: right;
  cursor: pointer;
}

.headSearchClose {
  position: relative;
}

.headSearchClose span svg {
  fill: #53b029;
  width: 25px;
  margin-right: 5px;
  cursor: pointer;
}
.headSearchClose span svg path {
  fill: #53b029;
}

#react-select-2-listbox {
  z-index: 99999 !important;
}
