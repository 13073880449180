.shop-timing div {
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: left;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.shop-timing div span:first-child {
  min-width: 150px;
  text-transform: capitalize;
}

.shop-info-main .shop-info img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 10px;
}

.shop-info-main .shop-info .shop-detail2 {
  margin-top: 1rem;
}

.shop-info-main .shop-info .dark-shop-detail2 {
  margin-top: 1rem;
}

.shop-info-main .shop-info .shop-detail2 h5 {
  font-size: 2rem;
  margin: 0;
  color: #000000;
}

.shop-info-main .shop-info .dark-shop-detail2 h5 {
  color: #ffffff;
  font-size: 2rem;
  margin: 0;
}

.shop-info-main .shop-info .shop-detail2 p {
  margin: 0;
  font-size: 1.2rem;
}

.shop-info-main .shop-details {
  margin: 3rem 0;
}

.shop-info-main .shop-details .shop-sec {
  margin-bottom: 2rem;
}

.shop-info-main .shop-details .shop-sec h5 {
  font-size: 1.5rem;
  margin: 0;
}

.shop-info-main .shop-details .shop-sec p {
  margin: 0;
  font-size: 1.1rem;
  margin-top: 10px;
}

.shop-info-main .shop-details .shop-sec .shop-timing {
  margin-top: 15px;
}

.shop-right-info .shop-sec h5 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.shop-info-row {
  display: flex;
  flex-wrap: wrap;
}

.shop-info-row .shop-info-main {
  /* width: 25%; */
  /* padding-right: 2rem; */
  margin-top: -7rem;
}

.shop-info-row .shop-right-info {
  width: 35%;
  padding-right: 2rem;
}

.shop-info-row .shop-member-info {
  width: 35%;
}

.shop-member-info .shop-sec h5 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.shop-member-info .shop-sec .shop-members .member-info img {
  width: 60px;
  height: 60px;
  border-radius: 50px;
  object-fit: cover;
  display: block;
  text-align: center;
  margin: 0 auto;
}

.shop-member-info .shop-sec .shop-members .member-info span {
  display: block;
  text-align: center;
  margin-top: 4px;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
}

.shop-member-info .shop-sec .shop-members {
  display: flex;
  justify-content: flex-start;
  align-items: top;
  gap: 10px;
  flex-wrap: wrap;
  max-height: 350px;
  overflow-y: auto;
}

.shop-member-info .shop-sec .shop-members .member-info {
  max-width: 150px;
  width: 150px;
}

.shop-info2 .shop-details .shop-sec h5 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.shop-info2 .shop-details .shop-sec p {
  margin: 0;
  font-size: 1.1rem;
  margin-top: 5px;
}

.tabs-main {
  margin: 2rem;
}

.tabs .tabs_btn {
  background: #ffffff;
  border: 0;
  font-size: 1.2rem;
  font-weight: 500;
  width: 100%;
  box-shadow: 0 0 10px #cccccc;
  padding: 0.8rem 0;
  border-radius: 5px;
  color: #53b029;
  cursor: pointer;
}

.tabs .tabs_active_btn {
  background: #53b029;
  border: 0;
  font-size: 1.2rem;
  font-weight: 500;
  width: 100%;
  box-shadow: 0 0 10px #cccccc;
  padding: 0.8rem 0;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
}

.tabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  gap: 20px;
}

.tabs-main .shop-right-info {
  margin-top: 3rem;
}

.tabs-main .shop-info2 {
  margin-top: 3rem;
}

.tabs-main .shop-member-info {
  margin-top: 3rem;
}

@media only screen and (max-width: 480px) {
  .modal .shop-modal-content {
    width: 90%;
  }
  .modal .tabs-main {
    margin: 1rem;
  }
  .tabs .tabs_active_btn {
    font-size: 0.8rem;
  }
  .tabs .tabs_btn {
    font-size: 0.8rem;
  }
  .shop-timing div span:first-child {
    min-width: 120px;
  }
  .shop-member-info .shop-sec .shop-members .member-info span {
    font-size: 0.8rem;
  }
  .shop-member-info .shop-sec .shop-members .member-info {
    max-width: 100px;
  }
}
