* {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Poppins", sans-serif;
}

.menuTabs .prevArrow {
  position: absolute;
  left: -65px;
  top: 22%;
  z-index: 12;
  background: #43b028;
  border-radius: 50%;
  cursor: pointer;
  width: 42px;
  height: 42px;
  padding: 8px;
  box-sizing: border-box;
  box-shadow: 0 1px 13px rgb(17 17 17 / 34%);
}

.menuTabs .nextArrow {
  position: absolute;
  right: -65px;
  top: 22%;
  z-index: 12;
  background: #43b028;
  border-radius: 50%;
  cursor: pointer;
  width: 42px;
  height: 42px;
  padding: 9px;
  box-sizing: border-box;
  box-shadow: 0 1px 13px rgb(17 17 17 / 34%);
}

.menuTabs .slick-slide {
  padding: 0 10px;
  box-sizing: border-box;
}

.lightmenuTabs div.slick-slide div button {
  border: 2px solid #43b028;
}
