.liveStream-chat {
  width: 45%;
  height: 100%;
  border-radius: 3px;
  overflow: hidden;
}

.liveStream-chat .chat-header {
  background: #464545;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  padding: 8px 22px;
  box-sizing: border-box;
}

.liveStream-chat .chat-header p {
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
}

.liveStream-chat .chat-body {
  background: #262525;
  height: 94%;
  padding: 18px 23px;
  box-sizing: border-box;
  position: relative;
}

.liveStream-chat .chat-body .chat-list span.chat-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.liveStream-chat .chat-body .chat-list span.chat-item img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50px;
}

.liveStream-chat
  .chat-body
  .chat-list
  span.chat-item
  .chat-text
  .chat-username {
  font-size: 1rem;
  font-weight: 600;
  color: #ffffff;
  width: 100%;
  display: block;
}

.liveStream-chat .chat-body .chat-list span.chat-item .chat-text .chat-message {
  font-size: 1rem;
  font-weight: normal;
  color: #cecece;
  width: 100%;
  display: block;
}

.liveStream-chat .chat-body .chat-list {
  padding-bottom: 23px;
  box-sizing: border-box;
}

.liveStream-chat .chat-body .chat-sendMsg {
  display: flex;
  align-items: center;
  gap: 30px;
  position: absolute;
  width: 100%;
  bottom: 20px;
  left: 0;
  padding: 0 10px;
}

.liveStream-chat .chat-body .chat-sendMsg .sendMsg-input {
  width: 100%;
}

.liveStream-chat .chat-body .chat-sendMsg .sendMsg-input input {
  background: #ffffff;
  border: 1px solid #cecece;
  border-radius: 30px;
  padding: 0px 12px 0px 16px;
  min-height: 48px;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  color: #0b0909;
  font-family: "Poppins", sans-serif;
}

.liveStream-chat .chat-body .chat-sendMsg .sendMsg-Btn button {
  background: transparent;
  border: 0;
  cursor: pointer;
  border-radius: 50px;
}

.liveStream-chat .str-chat {
  height: 100%;
}

.liveStream-chat .str-chat__input-flat {
  padding: var(--xs-p) var(--xs-p);
}

.liveStream-chat .rta textarea {
  overflow: hidden;
}

.liveStream-chat
  .dark.str-chat
  .str-chat__message-simple--me
  .str-chat__message-simple-text-inner {
  background: #ffffff;
  font-family: "Poppins", sans-serif;
}

.liveStream-chat .str-chat__message-simple-text-inner p {
  color: #0b0909;
  font-family: "Poppins", sans-serif;
  margin: 0;
}

.liveStream-chat .str-chat__message-simple-text-inner pre {
  color: #0b0909;
  font-family: "Poppins", sans-serif;
  margin: 0;
}

.liveStream-chat .str-chat__fileupload-wrapper {
  display: none;
}

.liveStream-chat .basic-text {
  color: #ffffff;
  padding: 1rem;
  text-align: center;
}

.liveStream-chat .basic-text a {
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 1px;
  text-decoration: none;
}

.liveStream-chat .str-chat__header-livestream-left--members {
  display: none;
}

.liveStream-chat .str-chat__header-livestream-left--title {
  font-weight: 500;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  font-size: 1.1rem;
}

.liveStream-chat .str-chat__avatar {
  display: none;
}

.liveStream-chat .str-chat__main-panel {
  padding: 0 !important;
}

.liveStream-chat .str-chat__header-livestream {
  min-height: auto;
}

.liveStream-chat .str-chat__input-flat .str-chat__send-button {
  display: block !important;
  cursor: pointer;
}

.liveStream-chat .str-chat__input-flat .str-chat__send-button svg path {
  fill: #53b029;
}

.dark.str-chat .str-chat__message-simple-data span{
  font-size: 10px !important;
  font-weight: 600 !important;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

.dark.str-chat .str-chat__message-simple-data time{
  font-size: 10px !important;
  font-weight: normal !important;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

@media only screen and (max-width: 480px) {
  .liveStream-chat {
    width: 100%;
  }
  .liveStream-chat .chat-body {
    height: 100vh;
  }
  .liveStream-chat .str-chat__header-hamburger {
    display: none;
  }
  .liveStream-video .live_video {
    height: 100vh;
    margin-bottom: 30px;
  }
}
