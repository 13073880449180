.pagination-main {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
}

.pagination-main button {
  list-style: none;
  background: #ffffff;
  color: #000;
  padding: 7px 2rem;
  text-align: center;
  margin-left: 1rem;
  border-radius: 3px;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  border: 0;
}

.pagination-main button:hover {
  background: #8bc63e;
  color: #ffffff;
  border: 0;
  font-style: italic;
}

.pagination-main-light button {
  background: linear-gradient(147.67deg, #43B028 24.24%, #FBCE2E 145.04%);
  color: #ffffff;
}
