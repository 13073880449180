.footer {
  background: #16161a;
  width: 100%;
  padding: 3.125rem 0 0 0;
}

.lightFooter {
  background: linear-gradient(147.67deg, #43B028 24.24%, #FBCE2E 145.04%);
  width: 100%;
  padding: 3.125rem 0 0 0;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.footer .info img {
  margin-bottom: 28px;
}

.footer .info span.call {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 1.125rem;
  font-weight: normal;
  line-height: 27px;
  color: #ffffff;
}

.footer .footer-menu ul {
  padding: 0;
  margin-top: 0;
}

.footer .footer-menu ul li {
  list-style: none;
  padding-bottom: 15px;
}

.footer .footer-menu ul li:last-child {
  padding-bottom: 0;
}

.footer .footer-menu ul li.menu-title {
  list-style: none;
  font-weight: 600;
  font-size: 1rem;
  line-height: 24px;
  color: #ffffff;
  margin-bottom: 23px;
  text-transform: uppercase;
  padding-bottom: 0;
}

.footer .footer-menu ul li a {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 21px;
  color: #ffffff;
  text-decoration: none;
}

.footer .footer-menu li.social-action {
  display: flex;
  gap: 33px;
}

.copyright {
  width: 100%;
  text-align: center;
  padding: 31px 0;
  border-top: 1px solid rgba(215, 218, 220, 0.4);
}

.copyright svg {
  display: block;
}

.copyright p {
  color: #ffffff;
  margin: 0;
  font-size: 1rem;
  line-height: 24px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
}

@media only screen and (max-width: 480px) {
  .footer .flex-row {
    flex-direction: column;
    gap: 40px;
    padding: 0 2rem;
  }
  .footer .footer-menu ul li {
    padding-bottom: 5px;
  }
  .footer .footer-menu ul {
    margin-bottom: 0;
  }
  .footer .footer-menu li.social-action {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .home-main .scrollTop span {
    right: 2rem;
  }
  .video-main .nextArrow {
    right: 0;
  }
  .video-main .prevArrow {
    left: 0;
  }
  .menuTabs .nextArrow {
    right: 0;
  }
  .menuTabs .prevArrow {
    left: 0;
  }
}
