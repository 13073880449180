.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
  z-index: 999;
}

.modal.enter-done {
  opacity: 1;
  pointer-events: visible;
}

.modal.exit {
  opacity: 0;
}

.modal-content {
  width: 400px;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
  transform: translateY(-200px);
  border-radius: 10px;
  /* height: 100%; */
  overflow: auto;
}

.modal-content::-webkit-scrollbar {
 display: none;
}

.modal.enter-done .modal-content {
  transform: translateY(0);
}

.modal.exit .modal-content {
  transform: translateY(-200px);
}

.modal-header,
.modal-footer {
  padding: 10px;
  text-align: center;
}

.modal-title {
  margin: 0;
  font-size: 1rem;
  color: #53b029;
}

.modal-body {
  padding: 10px;
  border-top: 1px solid rgb(83 176 41 / 49%);
  border-bottom: 1px solid rgb(83 176 41 / 49%);
}

.modal-closeBtn button {
  background: transparent;
  border: 0;
  text-align: right;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
}

.modal-closeBtn button svg path {
  fill: #53b029;
}

.modal .shop-modal-content{
    width: 50%;
}