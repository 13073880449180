.login_form {
  padding: 1rem 1rem;
}

.custom_row {
  display: flex;
  flex-direction: column;
}

.login_form .custom_col {
  margin-bottom: 1rem;
}

.login_form .custom_col input {
  width: 100%;
  background: #ffffff;
  border: 1px solid #cacaca;
  border-radius: 5px;
  height: 45px;
  /* padding: 0 1rem; */
  font-size: 1rem;
}

.login_form .custom_col .input-label {
  margin-bottom: -12px;
  display: block;
}

.login_form .custom_col .error {
  padding: 0 1rem;
  color: red;
  font-size: 0.9rem;
}

.login_form .submit-btn {
  width: 100%;
  text-align: center;
  margin-top: 1rem;
}

.login_form .submit-btn button {
  background: #53b029;
  border: 0;
  width: 100%;
  border-radius: 50px;
  padding: 0.8rem;
  font-size: 1rem;
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  letter-spacing: 1px;
}

.custom_row .custom_col .divider_text {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 2rem;
  margin: 2rem 0 1.2rem 0;
}

.custom_row .custom_col .divider_text span {
  background: #cccccc;
  height: 1px;
  width: 100%;
}

.custom_row .custom_col .google-signIn button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.6rem;
  font-weight: 600;
  text-transform: uppercase;
  color: #53b029;
  border: 2px solid;
  border-radius: 50px;
  padding: 0.5rem 0;
  width: 100%;
  font-size: 1rem;
  cursor: pointer;
}

.custom_row .custom_col .google-signIn button svg {
  width: 30px;
  display: block;
  height: 30px;
}

.custom_row .custom_col span.bottom_link {
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  gap: 8px;
}

.custom_row .custom_col span.bottom_link p {
  color: #53b029;
  text-decoration: none;
  display: block;
  margin: 0;
  cursor: pointer;
}

.custom_row .resend_otp {
  text-align: center;
  width: 100%;
  display: block;
  margin-top: 1rem;
}

.custom_row .resend_otp .link-btn {
  background: transparent;
  border: 0;
  font-size: 1rem;
  color: #53b029;
  text-decoration: underline;
  cursor: pointer;
}

.guest_user_link button {
  background: transparent;
  border: 0;
  font-size: 1rem;
  text-align: center;
  margin: 0 auto;
  width: 100%;
  letter-spacing: 1px;
  font-weight: 600;
  color: #53b029;
  text-transform: uppercase;
  cursor: pointer;
}

.guest_user_link button:hover {
  color: #000000;
}
