.shop-main .shop-list-container {
  align-items: top;
}

.shop-main .shop-list-container .shop_list a {
  text-decoration: none;
  color: #000000;
}

.shop-main .shop_list {
  width: 25%;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 35px;
}

.shop-main .shop_list .shop-box {
  background: #ffffff;
  border-radius: 10px;
}

.shop-main .shop_list .light-shop-box {
  /* background: #000000; */
  box-shadow: 0 0 18px -2px rgb(0 0 0 / 36%);
}

.shop-main .shop_list .shop-box .shop-img img {
  width: 100%;
  height: 250px;
  max-height: 250px;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 1px solid #43b028;
}

.shop-main .shop_list .shop-box .shop-details {
  padding: 3px 15px;
  min-height: 150px;
}

.shop-main .shop_list .shop-box .shop-details h4 {
  margin: 0;
  color: #000000;
  font-weight: 500;
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.shop-main .shop_list .shop-box .shop-details .address {
  display: flex;
  align-items: top;
  gap: 10px;
}

.shop-main .shop_list .shop-box .shop-details .address svg {
  height: 23px;
  width: 18%;
  max-height: 23px;
  max-width: 23px;
}

.shop-main .shop_list .shop-box .shop-details p {
  margin: 0;
  color: #000000;
}

@media only screen and (max-width: 480px) {
  .shop-main .shop_list {
    width: 100%;
  }
  .shop-info-row .shop-info-main {
    display: none;
  }
  .mobile-shop-image {
    display: block;
  }
  .mobile-shop-image img {
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 10px;
  }
}

@media (min-width: 481px) {
  .mobile-shop-image {
    display: none;
  }
  .shop-info-row .shop-info-main {
    display: block;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .shop-main .shop_list {
    width: 50%;
  }
}
