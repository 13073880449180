.liveStream-video {
  width: 28%;
}

.liveStream-video .live_video {
  position: relative;
  height: 100%;
}

.liveStream-video .live_video video {
  width: 100%;
  height: 100%;
  border-radius: 3px;
  object-fit: cover;
}

.liveStream-video .live_video video:-webkit-full-screen {
  object-fit: contain;
}

.liveStream-video .live_video .videoHeader {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  width: 100%;
  padding: 0.8rem 1rem;
  box-sizing: border-box;
  z-index: 99;
  flex-wrap: wrap;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0.9) 35%,
    rgba(0, 0, 0, 0.2) 100%
  );
}

.liveStream-video .live_video .videoHeader .video-content .iconLabel {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 0.5rem;
}

.liveStream-video .live_video .videoHeader .video-content .iconLabel img {
  width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 50px;
}

.liveStream-video .live_video .videoHeader .video-content .iconLabel span {
  font-size: 1rem;
  font-weight: 600;
  color: #ffffff;
}

.liveStream-video .live_video .videoHeader .video-content a{
  text-decoration: none;
  color: #ffffff;
}

.liveStream-video .live_video .videoHeader .video-content .iconImg {
  display: flex;
  gap: 8px;
  align-items: center;
}

.liveStream-video .live_video .videoHeader .video-content .iconImg img {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 3px;
}

.liveStream-video .live_video .videoHeader .video-content .iconImg p {
  margin: 5px 0 0 0;
  font-weight: 600;
  font-size: 1rem;
  text-transform: capitalize;
}

.liveStream-video .live_video .videoHeader .video-content .streamer_info {
  margin-top: 10px;
}

.liveStream-video .live_video .videoHeader .video-content .streamer_info img {
  border-radius: 50px;
}

.liveStream-video .live_video .videoHeader .video-status {
  display: flex;
  gap: 16px;
}

.liveStream-video .live_video .videoHeader .video-status .live-views {
  background: #0b0909;
  border-radius: 30px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  font-weight: 500;
  color: #ffffff;
  height: 28px;
  gap: 6px;
  box-sizing: border-box;
}

.liveStream-video .live_video .videoHeader .video-status .live-status {
  background: #db3d12;
  border-radius: 30px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  color: #ffffff;
  height: 28px;
  gap: 6px;
  box-sizing: border-box;
}

.liveStream-video .live_video .videoHeader .video-status .video-onAir {
  background: #43b028;
  border-radius: 30px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  color: #ffffff;
  height: 28px;
  gap: 6px;
  box-sizing: border-box;
}

.liveStream-video .live_video .videoHeader .video-status .close-btn {
  cursor: pointer;
}

.liveStream-video .live-actionBtn {
  position: absolute;
  bottom: 55px;
  right: 18px;
}

.liveStream-video .live-actionBtn svg {
  display: block;
  cursor: pointer;
  margin: 0 auto;
}

.liveStream-video .live-actionBtn .live-cart {
  display: block;
  font-size: 1rem;
  font-weight: 300;
  color: #ffffff;
  text-align: center;
  margin-bottom: 1rem;
  background: #ffffff;
  border-radius: 50px;
  width: 48px;
  height: 48px;
  cursor: pointer;
}

.liveStream-video .live-actionBtn .live-likes {
  display: flex;
  font-size: 1rem;
  font-weight: 300;
  color: #ffffff;
  text-align: center;
  margin-bottom: 1rem;
  background: #ffffff;
  border-radius: 50px;
  width: 48px;
  height: 48px;
  cursor: pointer;
  align-items: center;
}

.liveStream-video .live-actionBtn .live-cart svg {
  width: 25px;
  display: block;
  text-align: center;
  margin: 0 auto;
  padding: 1px;
}

.liveStream-video .live-actionBtn .live-likes p {
  padding-top: 4px;
  margin: 0;
}

.liveStream-video .live-actionBtn .live-share {
  display: block;
  font-size: 1rem;
  font-weight: 300;
  color: #ffffff;
  text-align: center;
}

.liveStream-video .live-actionBtn .live-share p {
  padding-top: 4px;
  margin: 0;
}

.overflow-text {
  overflow: hidden;
  word-wrap: break-word;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 160px;
}

.backBtn button {
  background: #ffffffe1;
  border: 0;
  color: #000;
  display: block;
  width: 50px;
  height: 50px;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
  border-bottom-right-radius: 30px;
  z-index: 999;
}

.stream_ended_popup {
  text-align: center;
  padding: 2rem 1rem;
  display: block;
}

.stream_ended_popup h3 {
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0;
  margin-bottom: 1.5rem;
}

.stream_ended_popup a {
  background: #43b028;
  color: #ffffff;
  font-size: 1rem;
  border: 0;
  /* display: block; */
  text-decoration: none;
  border-radius: 3px;
  padding: 0.5rem 2rem;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
}

@media only screen and (max-width: 480px) {
  .liveStream-video {
    width: 100%;
  }
  .liveStream-video .live_video .videoHeader {
    padding: 1rem 0.4rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .liveStream-video {
    width: 43%;
  }
  .liveStream-chat {
    width: 55% !important;
  }
}
